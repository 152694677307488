<template>
    <div>
        <Loader :isLoading="processingForm || isLoading" />
        <div class="commen-hader">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h2>Contact Us</h2>
                        <p>
                            Feel free to contact us for any queries.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="formSubmitted"
            class="m-4 p-4 text-center"
            style="background: hsl(120, 100%, 90%)"
        >
            <p>
                Your message has been sent successfully.
                <br />We will reach back to you soon. Thank you.
            </p>
        </div>
        <div v-else class="vacancies_area">
            <div class="container">
                <form ref="anyName" enctype="multipart/form-data" @submit.prevent="submit">
                    <div class="row">
                        <div class="col-md-7">
                            <template v-if="!this.session_message">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="First Name"
                                                id="first"
                                                v-model="firstName"
                                            />
                                            <FormError :title="formErrors.firstName" />
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Last Name"
                                                id="lastName"
                                                v-model="lastName"
                                            />
                                            <FormError :title="formErrors.lastName" />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input
                                                type="tel"
                                                class="form-control"
                                                id="phone"
                                                placeholder="Mobile Number"
                                                v-model="mobile"
                                            />
                                            <FormError :title="formErrors.mobile" />
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <input
                                                type="email"
                                                class="form-control"
                                                placeholder="Email ID"
                                                id="email"
                                                v-model="email"
                                            />
                                            <FormError :title="formErrors.email" />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <!-- <div class="col-md-6">
                                        <div class="form-group">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="website"
                                                placeholder="ex: http://www.epc.com"
                                                v-model="website"
                                                @keyup="validateWebsite"
                                            />
                                            <FormError :title="formErrors.website" />
                                        </div>
                                    </div> -->

                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <textarea
                                                type="textarea"
                                                class="form-control"
                                                id="query"
                                                rows="5"
                                                placeholder="Street, Landmark etc"
                                                v-model="address"
                                            ></textarea>
                                            <FormError :title="formErrors.address" />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <!-- <div class="col-md-6">-->
                                    <!--<div class="form-group">-->

                                    <!--        <vue-dropzone-->
                                    <!--          ref="myVueDropzone"-->
                                    <!--          id="dropzone"-->
                                    <!--          :options="dropzoneOptions"-->
                                    <!--          :useCustomSlot="true"-->

                                    <!--          @vdropzone-success="uploadSuccess">-->
                                    <!--          <div class="dropzone-custom-content">-->
                                    <!--            <h3 class="dropzone-custom-title">-->
                                    <!--              Drag your certificates or choose from computer-->
                                    <!--            </h3>-->
                                    <!--          </div>-->
                                    <!--        </vue-dropzone>-->

                                    <!--      <div class="status small" id="image-status"></div>-->
                                    <!--</div>-->
                                    <!--</div>-->

                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <button type="submit" class="primary-button ctnbtn">
                                                Send Message
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <div class="col-md-12" v-if="this.session_message">
                                <div class="row">
                                    <!-- <div class="col-md-2"></div> -->
                                    <div class="col-md-6 offset-md-3 pt-4 px-3 successmessage">
                                        <h3>Thank you for reaching out to us.</h3>
                                        <p>
                                            We’ll get back to you in the next 1-2 business days on
                                            your email
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5 contact-info-container">
                            <h5>Contact Info</h5>
                            <hr color="white" />
                            <div>
                                Office No.2, Block-B, Oxford Greenwood Commercial Complex, Near
                                Hiranandani Meadows, Chitalsar Manpada, Ghodbunder Road,
                                Thane-400610, Maharashtra, India
                            </div>
                            <br />
                            <div>
                                {{ contact_email }}
                            </div>
                            <br />
                            <div>
                                {{ contact_phone }}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
import { HTTP } from "../_helper/http-constants"
// import vue2Dropzone from "vue2-dropzone"
// import "vue2-dropzone/dist/vue2Dropzone.min.css"
import axios from "axios"
import request from "../apis/request"
import scrollTop from "./utils/scrollTop"
import FormError from "./utils/FormError"
import Loader from "./Loader.vue"

export default {
    name: "Contact",
    components: {
        // vueDropzone: vue2Dropzone,
        FormError,
        Loader,
    },
    watch: {
        firstName() {
            this.updateErrors()
        },
        lastName() {
            this.updateErrors()
        },
        mobile() {
            this.updateErrors()
        },
        email() {
            this.updateErrors()
        },
        address() {
            this.updateErrors()
        },
        website() {
            this.updateErrors()
        },
    },
    data() {
        return {
            startValidation: false,
            formSubmitted: false,
            processingForm: false,
            user: "",
            filelist: [],
            firstName: "",
            isLoading: false,
            lastName: "",
            mobile: "",
            email: "",
            address: "",
            session_message: false,
            website: "",
            image: "",
            images: "",
            formErrors: {},
            //   imgsrc: 'http://103.212.120.205/~dev/public/admin_profile/',
            //   imgsrc: "http://localhost/backend/public/admin_profile/",

            imgsrc: axios.defaults.uploadURL,

            dropzoneOptions: {
                url: "https://httpbin.org/post",

                thumbnailWidth: 150,
                maxFilesize: 2,
                addRemoveLinks: true,
                paralleluploads: 1,
                maxFiles: 1,
                acceptedFiles: ".jpg, .jpeg, .png, .bmp, .pdf",
                headers: { "My-Awesome-Header": "header value" },
                // autoProcessQueue: false,
            },
        }
    },

    methods: {
        updateErrors() {
            if (!this.startValidation) return
            this.formErrors = {}
            if (!this.firstName) {
                this.formErrors.firstName = "First name is required."
            }
            if (!this.lastName) {
                this.formErrors.lastName = "Last name is required."
            }
            if (!this.mobile) {
                this.formErrors.mobile = "Mobile number is required."
            }
            if (!this.mobile) {
                this.formErrors.mobile = "Email is required."
            }
            if (!this.address) {
                this.formErrors.address = "Address is required."
            }

            this.validateEmail()
            this.validatemobile()
            this.validateWebsite()
        },
        submit() {
            if (this.processingForm) return
            this.startValidation = true
            this.updateErrors()
            if (
                !(
                    this.validateEmail() &&
                    this.validateWebsite() &&
                    this.validatemobile() &&
                    this.firstName &&
                    this.lastName &&
                    //   this.image &&
                    this.address
                )
            ) {
                this.$toasted.error("Please correct the errors before submitting.")
                return
            }

            const form = new FormData()

            form.append("first_name", this.firstName)
            form.append("last_name", this.lastName)
            form.append("email", this.email)
            form.append("mobile", this.mobile)
            form.append("address", this.address)
            form.append("website", this.website)
            //   form.append("image", this.images)

            this.processingForm = true

            request
                .post("/contact", form, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    // console.log("email-send");
                    this.processingForm = false
                    if (res) {
                        this.session_message = true

                        this.$toasted.success("Message Sent Successfully", {
                            keepOnHover: true,
                            iconPack: "fontawesome",
                            icon: "check",
                            theme: "toasted-primary",
                            // timerProgressBar: true,
                            duration: 3000,
                        })
                        this.formSubmitted = true
                        this.$refs.anyName.reset()
                    }
                })
                .catch((e) => {
                    this.processingForm = false
                    this.$toasted.error("Could not submit form. Please try again later.")
                    console.log("err message", e.message)
                })
        },
        validateEmail: function() {
            const regMail = /^([_a-zA-Z0-9-]+)(\.[_a-zA-Z0-9-]+)*@([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,3})$/
            let res = regMail.test(this.email)
            let msg
            if (res === false) msg = "Email is not valid yet"
            this.formErrors.email = msg
            return res
        },
        validateWebsite: function() {
            // website is optional
            if (!this.website) return true
            const regWebsite = /^https?:\/\/\w+(\.\w+)*(:[0-9]+)?\/?$/
            let res = regWebsite.test(this.website)
            let msg
            if (res === false) msg = "Website is not valid!"
            this.formErrors.website = msg
            return res
        },

        validatemobile: function() {
            let res = true
            let digitCount = 0
            for (let i = 0; i < this.mobile.length; i++) {
                if (!(this.mobile[i] >= "0" && this.mobile[i] <= "9")) {
                    res = false
                } else {
                    digitCount++
                }
            }
            let msg
            if (res == false) msg = "Only digits are allowed without any spaces!"
            else if (digitCount != 10) msg = "Mobile no. must be 10 digits"
            this.formErrors.mobile = msg
            return res && digitCount == 10
        },

        onChange() {
            this.filelist = [...this.$refs.file.files]
            console.log(this.filelist)
        },
        remove(i) {
            this.filelist.splice(i, 1)
        },
        dragover(event) {
            event.preventDefault()
            // Add some visual fluff to show the user can drop its files
            if (!event.currentTarget.classList.contains("bg-green-300")) {
                event.currentTarget.classList.remove("bg-gray-100")
                event.currentTarget.classList.add("bg-green-300")
            }
        },
        dragleave(event) {
            // Clean up
            event.currentTarget.classList.add("bg-gray-100")
            event.currentTarget.classList.remove("bg-green-300")
        },
        drop(event) {
            event.preventDefault()
            this.$refs.file.files = event.dataTransfer.files
            this.onChange() // Trigger the onChange event manually
            // Clean up
            event.currentTarget.classList.add("bg-gray-100")
            event.currentTarget.classList.remove("bg-green-300")
        },

        // vdropzone-complete
        uploadSuccess(file, response) {
            // console.log(file.name);
            this.image = file.name
            this.images = file
        },

        fileRemoved() {},
    },

    mounted() {
        scrollTop()
        HTTP.get("/api/job").then((res) => {
            this.user = res.data
        })
    },
    beforeDestroy() {
        this.isLoading = true
        console.log("contact page unmounting")
    },
    computed: {
        ...mapState("contact", {
            contact_email: "email",
            contact_phone: "phone",
        }),
    },
}
</script>

<style>
.imagePreview {
    width: 50%;
    height: 90px;
    background-position: center center;
    background: url(http://cliquecities.com/assets/no-image-e3699ae23f866f6cbdf8ba2443ee5c4e.jpg);
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    display: inline-block;
    box-shadow: 0px -3px 6px 2px rgba(0, 0, 0, 0.2);
}

.imgUp {
    margin-bottom: 15px;
}

.dropzone .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0px !important;
    min-height: 100px;
}

.dropzone {
    min-height: 143px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    background: white;
    padding: 0px !important;
}

.contact-info-container {
    background: rgba(84, 84, 84, 0.9);
    color: white;
    font-size: 0.9em;
    padding: 15px;
}
</style>
